import styled from 'styled-components';
import Thewebsite from '../components/Home/Thewebsite';
import websitedata from '../Json/websitedata.json';
import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"


const SectionWebsiteGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

export default function WebsitePage() {
  return (
    <Layout>
    <Seo title="Website Development" />
    <div className="Hero">
      <div className="HeroGroup">
      <h2>Website development</h2>
        <SectionWebsiteGroup>
          {websitedata.thewebsites.map((thewebsite) => (
            <Thewebsite
              title={thewebsite.title}
              image={thewebsite.image}
              text={thewebsite.text}
              url={thewebsite.url}
            />
          ))}
        </SectionWebsiteGroup>
      </div>
    </div>
    </Layout>
  );
}
